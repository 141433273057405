$color-primary : #3730a3;
$color-error: red;
$color-success: green;

/* MEDIA QUERIES */
$small-screen: 360px;
$mobile-below: 767px;
$ipad-portrait-start: 768px;
$ipad-portrait-end: 1023px;
$ipad-landscape-start: 1024px;
$desktop-start: 1025px;
$large-monitor-start: 1400px;