@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/variables';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page__container {
  h1 {
    font-size: 24px;
    font-weight: 600;
  }
}

.page-details__container {
  padding-top: 20px;
}

.error {
  color: $color-error;
}

.success {
  color: $color-success;
}

button[disabled], button.disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}