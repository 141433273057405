@import '../../styles/variables.scss';

.global__header {
    padding-bottom: 100px;
    .site-logo {
        padding: 5px 8px;
        background-color: $color-primary;
        color: #ffffff;
        border-radius: 5px;
        margin-right: 10px;
        font-weight: 600;
    }
}